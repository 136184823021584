@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#__next {
  @apply h-full;
}

body {
  @apply bg-silver-5;
}

@layer components {
  /* 
    the calc is to prevent the otherside from scrolling, the calc is based on static sizes of other divs
    nearby, the numbers used are thus: 106+57+57+5.25rem.
    To use calc w/ tailing, you need to use underscores instead of spaces
  */
  .h-detail-tab {
    @apply max-h-[calc(100vh_-_220px_-_5.25rem)];
  }
}

@layer utilities {
  /*
    These utilities remove the default styling of number inputs and
    datalist inputs, making it easier to format them accordingly to
    the designs, by just adding "appearance-none-input" className
  */
  input.appearance-none-input[type='number']::-webkit-inner-spin-button,
  input.appearance-none-input[type='number']::-webkit-outer-spin-button {
    @apply appearance-none;
  }
  .appearance-none-input::-webkit-calendar-picker-indicator {
    display: none !important;
    @apply appearance-none;
  }
  select::-ms-expand {
    display: none;
    @apply appearance-none;
  }
}
